<template>
  <div>
    <span class="viewH">Контакты</span>
    <hr>
    <div class="social">
      <dir class="title-name">СоцСети</dir>
      <v-simple-table dark>
        <template v-slot:default>
          <tbody v-for="(soc, i) in getSocial" :key="'soc' + i">
            <tr>
              <td style="width: 30px;">
                <v-btn
                  title="Редактировать"
                  icon
                  color="red"
                  @click="editSoc(soc.id, soc.type, soc.val_)"
                >
                  <v-icon color="#FF4500">mdi-lead-pencil</v-icon>
                </v-btn>
              </td>
              <td>{{soc.type}}</td>
              <td v-if="id != soc.id" class="not-input-line">{{soc.val_}}</td>
              <td v-else class="input-line">
                <v-text-field
                  outlined
                  dense
                  hide-details
                  :value="soc.val_"
                ></v-text-field>
              </td>
              <td>
                <div class="btn-wrap" v-if="id == soc.id">
                  <v-btn icon class="btn" @click="saveSoc()"> <v-icon color="green">mdi-check-circle</v-icon> </v-btn>
                  <v-btn icon class="btn" @click="cancel()"> <v-icon color="red">mdi-close-circle</v-icon> </v-btn>
                </div>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </div>
    <div class="phone">
      <dir class="title-name">Телефоны</dir>
      <v-simple-table dark>
        <template v-slot:default>
          <tbody v-for="(city, c) in getCity" :key="'city' + c">
            <tr>
              <td style="width: 30px;">
                <v-btn icon color="green" @click="insPhone(city.value, city.text)" :title="`Добавить телефон ${city.text}`">
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </td>
              <td>{{city.text}}</td>
            </tr>
            <tr v-for="(phone, p) in getPhone" :key="'phone' + p">
              <td v-if="city.value == phone.city_id">
                <v-btn icon color="red" @click="delPhone(phone.id)" :title="`Удалить телефон ${city.text}`">
                  <v-icon>mdi-minus</v-icon>
                </v-btn>
              </td>
              <td v-if="city.value == phone.city_id">{{phone.phone}}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </div>
    <div class="address">
      <dir class="title-name">Адреса</dir>
       <v-simple-table dark>
        <template v-slot:default>
          <tbody v-for="(city, c) in getCity" :key="'city' + c">
            <tr>
              <td style="width: 30px;">
                <v-btn icon color="green" @click="insAddress(city.value, city.text)" :title="`Добавить адрес ${city.text}`">
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </td>
              <td colspan="3">{{city.text}}</td>
            </tr>
            <tr 
              v-for="(address, p) in getAddress" 
              :key="'address' + p"
            >
              <td 
                v-if="city.value == address.city_id" 
                style="text-align: center"
              >
                <v-btn 
                  icon color="red"
                  @click="editAddress(address.id, address.city_id, address.address, address.map, address.worktime)"
                  :title="`Редактировать адрес ${city.text}`"
                >
                  <v-icon color="#FF4500">mdi-lead-pencil</v-icon>
                </v-btn>
              </td>
              <td v-if="city.value == address.city_id">{{address.address}}</td>
              <td v-if="city.value == address.city_id">{{address.map}}</td>
              <td v-if="city.value == address.city_id">{{address.worktime}}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </div>
    <v-dialog
      v-model="dialogPhone"
      persistent
      max-width="370"
    >
      <v-card>
        <div class="ins-phone-content">
          <v-form ref="phone">
            <v-text-field
              :label="'Введите номер телефона ('+city+')'"
              outlined
              dense
              v-model="phone"
              :rules="contactRules"
            ></v-text-field>
          </v-form>
          <div class="ins-phone-btn">
            <v-btn small color="red" @click="cancel">отмена</v-btn>
            <v-btn small color="green" @click="savPhone">сохранить</v-btn>
          </div>
        </div>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialogAddress"
      persistent
      max-width="370"
    >
      <v-card>
        <div class="ins-phone-content">
          <v-form ref="address">
            <v-text-field
              :label="'Введите адрес ('+city+')'"
              outlined
              dense
              v-model="address"
              :rules="contactRules"
            ></v-text-field>
            <v-text-field
              label="Введите режим работы"
              outlined
              dense
              v-model="worktime"
              :rules="contactRules"
            ></v-text-field>
            <v-text-field
              label="Вставте карту (адрес для iframe)"
              outlined
              dense
              v-model="map"
              :rules="contactRules"
            ></v-text-field>
          </v-form>
          <div class="ins-phone-btn">
            <v-btn small color="#FF4500" @click="cancel">отмена</v-btn>
            <v-btn small color="green" @click="updAddress" v-if="edit">изменить</v-btn>
            <v-btn small color="red" @click="delAddress" v-if="edit">удалить</v-btn>
            <v-btn small color="green" @click="savAddress" v-if="!edit">сохранить</v-btn>
          </div>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'
export default {
  data: () => ({
    dialogPhone: false,
    city: null,
    city_id: null,
    phone: null,
    dialogAddress: false,
    address: '',
    map: '',
    worktime: '',
    edit: false,
    id: null,
    type: null,
    val_: null,
    contactRules: [
      v => !!v || 'Поле не должно быть пустым!'
    ],
  }),
  computed: {
    ...mapGetters(['getSocial', 'getPhone', 'getAddress', 'getCity', 'getAddress'])
  },
  methods: {
    ...mapActions(['deletePhone', 'savePhone', 'updateAddress', 'deleteAddress', 'saveAddress']),
    editSoc(id, type, val_) {
      this.id = id
    },
    saveSoc() {

    },
    insPhone(id, city) {
      this.dialogPhone = true
      this.city_id = id
      this.city = city
    },
    async savPhone() {
      if (this.$refs.phone.validate()) {
          await this.savePhone({
          city_id: this.city_id, 
          phone: this.phone
        })
        this.cancel()
      }
    },
    async delPhone(id) {
      await this.deletePhone({id: id})
      this.cancel()
    },
    async savAddress() {
      if (this.$refs.address.validate()) {
          await this.saveAddress({
          city_id: this.city_id,
          address: this.address.trim(),
          map: this.map.trim(),
          worktime: this.worktime.trim()
        })
        this.cancel()
      }
    },
    insAddress(id, city) {
      this.dialogAddress = true
      this.city = city
      this.city_id = id
    },
    editAddress(id, city_id, address, map, worktime) {
      this.dialogAddress = true
      this.edit = true
      this.city = this.getCity.find(c => c.value == city_id).text
      this.id = id
      this.address = address
      this.map = map
      this.worktime = worktime
    },
    async updAddress(id) {
      await this.updateAddress({
        id: this.id,
        address: this.address.trim(),
        map: this.map.trim(),
        worktime: this.worktime.trim()
      })
      this.cancel()
    },
    async delAddress() {
      await this.deleteAddress({id: this.id})
      this.cancel()
    },
    cancel() {
      this.dialogAddress = false
      this.dialogPhone = false
      this.edit = false
      this.address = ''
      this.map = ''
      this.worktime = ''
      this.city_id = null
      this.id = null
      this.phone = null
      if(this.$refs.phone != undefined) this.$refs.phone.reset()
      if(this.$refs.address != undefined) this.$refs.address.reset()
    }
  }
}
</script>

<style lang="scss" scoped>
  .title-name {
    margin-bottom: 10px;
  }
  .social {
    margin: 25px 0;
    table {
      width: 100%;
      tr {
        td {
          padding: 2px 5px;
          .btn-wrap {
            width: 110px;
            .btn {
              margin: 0 2px;
            }
          }
          .input-line {
            padding: 2px 0 0 0;
          }
          .not-input-line {
            word-break: break-all;
          }
        }
      }
    }
  }
  .phone {
    margin: 25px 0;
  }
  .ins-phone-content {
    padding: 15px;
    .ins-phone-btn {
      text-align: center;
      button {
        margin: 0 5px;
      }
    }
  }
</style>
